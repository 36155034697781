.addressBtnComponent {
    position: relative;

    .addressImg {
        position: absolute;
        top: 8px;
        left: 20px;
    }

    .arrowImg {
        position: absolute;
        top: 19px;
        left: 217px;
    }
    
    
    button {
        // box-shadow: 0px 2px 5px 2px rgba(74, 74, 74, 0.7);
        background: #FFFFFF;
        box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
        border-radius: 25px;

        border: none;
        padding: 7px 40px 7px 60px;
        outline: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #000000;
    }
}