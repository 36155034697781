.showMoreBtn {
    background: #FFFFFF;
    border: 4px solid #FFB800;
    border-radius: 25px;
    padding: 7px 30px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.w-full {
    width: 100%;
}

.btnSmall {
    padding: 3px 15px;
}

.showMoreBtnType1 {
    background-color: #FFB800;
    color: black;
}
.showMoreBtnType2 {
    background-color: white;
    color: black;
}

.paddingNextStep {
    padding: 5px 100px;
}

@media only screen and (max-width: 991px) {
    .showMoreBtn {
        font-size: 18px;
    }
}

@media only screen and (max-width: 500px) {
    .showMoreBtn {
        font-size: 16px;
    }
    .noPadding {
        padding: 5px 20px;
    }
}