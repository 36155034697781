.cartIconComponent {
    .customDropDownCart {
        background: #FFFFFF;
        box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
        border-radius: 20px;
        padding: 15px;
        width: 250px;
    
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: #000000;
        }
    
        button {
            font-size: 16px;
            padding: 0px;
        }
    }
}