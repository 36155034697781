.headerSection {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // gap: 10px;

    .searchBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .iconBox {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
}

.customDropDownAddress {
    transform: none !important;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
    border-radius: 20px;
    padding: 0px;
    width: 100%;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000000;

    button {
        font-size: 16px;
    }

    .box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 10px;

        img {
            position: unset;
        }
    }
}


@media only screen and (max-width: 991px) {
    
}


